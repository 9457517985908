export class ConstantsService {
  activeList = [
    { name: 'Publish', value: 'published' },
    { name: 'Draft', value: 'draft' },
  ];
  userStatus = [
    { name: 'Active', value: 'active' },
    { name: 'Inactive', value: 'inactive' },
  ];
  adminRoles = [
    { name: 'Lekhani Admin', value: 'lekhani-admin' },
    { name: 'Super Admin', value: 'super-admin' },
    { name: 'Admin', value: 'admin' },
  ];
  languages = [
    {
      id: '63ecb6ed9f8e2e12084dcf31',

      name: 'Kannada',
      languageCode: 'KN',
    },
    {
      id: '63ecb7089f8e2e12084dcf32',

      name: 'English',
      languageCode: 'EN',
    },
    {
      id: '63ecb7159f8e2e12084dcf33',

      name: 'Tulu',
      languageCode: 'TU',
    },
  ];
}
