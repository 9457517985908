import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '@app/service/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  constructor(private authService: ApiService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.getToken() && this.authService.getUserRole()) {
      return true;
    } else {
      // this.router.navigate(['/dashboard']);
      this.router.navigate(['/login']);

      return false;
    }
  }
}
