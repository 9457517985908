import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpOptions: object;
  httpOptionsOne: object;
  accessToken: string;
  userId: string;
  //start
  private readonly USER_ROLE_KEY = 'userRole';
  private readonly SECRET_KEY = 'mySecretKey';
  private isLoggedIn = false;
  //end
  constructor(private http: HttpClient, private router: Router) {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJheWthcmFycGl0QGdtYWlsLmNvbSIsIm5hbWUiOiJhcnBpdCIsInBlcm1pc3Npb25zIjpbIkNyZWF0ZU93blVzZXIiLCJVcGRhdGVPd25Vc2VyIiwiRGVsZXRlT3duVXNlciIsIlZpZXdPd25Vc2VyIl0sImlhdCI6MTY4MDQ5Njc3OSwiZXhwIjoxNjgwNTE4Mzc5fQ.rG413Rz8-SK_LI608UEGM7uccr_1uKculEdH0PL-C8A`,
        }),
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    }
  }
  //////////////////////general apis for CRUD///////////////////

  //start
  setUserRole(role: string) {
    const encryptedRole = CryptoJS.AES.encrypt(
      role,
      this.SECRET_KEY
    ).toString();
    localStorage.setItem(this.USER_ROLE_KEY, encryptedRole);
  }
  //end
  itemsGetPaginationAscOrder(step, limits, endPoint) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        order: 'title ASC',
        limit: limits,
        skip: step,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  itemsGetPaginationOrderCondition(step, limits, endPoint, include) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      if (include.length > 0) {
        qd = {
          limit: limits,
          skip: step,
          include: include,
          order: 'createdDate DESC',
          // include: [{ relation: ['subject'] }],
          // include: ['subject', 'section'],
        };
      } else {
        qd = {
          limit: limits,
          skip: step,
          order: 'createdDate DESC',

          // include: [{ relation: ['subject'] }],
          // include: ['subject', 'section'],
        };
      }

      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  itemsGetPaginationOrderConditions(step, limits, endPoint, include, where) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      if (include.length > 0) {
        qd = {
          limit: limits,
          skip: step,
          include: include,
          where: where,
          order: 'createdDate DESC',
          // include: [{ relation: ['subject'] }],
          // include: ['subject', 'section'],
        };
      } else {
        qd = {
          limit: limits,
          skip: step,
          where: where,
          order: 'createdDate DESC',
          // include: [{ relation: ['subject'] }],
          // include: ['subject', 'section'],
        };
      }

      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationAscOrderInclude(step, limits, endPoint, include) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        order: 'createdDate DESC',
        limit: limits,
        skip: step,
        include: include,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationBookNameAscOrderInclude(step, limits, endPoint, include) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        order: 'createdDate DESC',
        limit: limits,
        skip: step,
        include: include,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      console.log("ENDPOINT: ", endPoint)
      console.log("QUERY: ", query)
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions
        )
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  itemsGetPaginationNameAscOrderInclude(
    step,
    limits,
    endPoint,
    include,
    where?,
    fields?
  ) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        order: 'createdDate DESC',
        limit: limits,
        skip: step,
        include: include,
        where: where,
        fields: fields,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}?${query}`,
          (this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJheWthcmFycGl0QGdtYWlsLmNvbSIsIm5hbWUiOiJhcnBpdCIsInBlcm1pc3Npb25zIjpbIkNyZWF0ZU93blVzZXIiLCJVcGRhdGVPd25Vc2VyIiwiRGVsZXRlT3duVXNlciIsIlZpZXdPd25Vc2VyIl0sImlhdCI6MTY4MDQ5Njc3OSwiZXhwIjoxNjgwNTE4Mzc5fQ.rG413Rz8-SK_LI608UEGM7uccr_1uKculEdH0PL-C8A`,
            }),
          })
        )
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationCustom(endPoint, qd) {
    return new Promise(async (resolve, reject) => {
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}?${query}`,
          (this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJheWthcmFycGl0QGdtYWlsLmNvbSIsIm5hbWUiOiJhcnBpdCIsInBlcm1pc3Npb25zIjpbIkNyZWF0ZU93blVzZXIiLCJVcGRhdGVPd25Vc2VyIiwiRGVsZXRlT3duVXNlciIsIlZpZXdPd25Vc2VyIl0sImlhdCI6MTY4MDQ5Njc3OSwiZXhwIjoxNjgwNTE4Mzc5fQ.rG413Rz8-SK_LI608UEGM7uccr_1uKculEdH0PL-C8A`,
            }),
          })
        )
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationOrder(step, limits, endPoint) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        order: 'createdDate DESC',
        limit: limits,
        skip: step,
        include: 'customers',
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationTransaction(step, limits, endPoint) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      var includes = ['order', 'productOrders', 'customer'];
      qd = {
        order: 'createdDate DESC',
        limit: limits,
        skip: step,
        include: includes,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationOrderInclude(
    step,
    limits,
    include,
    endPoint,
    whereCondition
  ) {
    return new Promise(async (resolve, reject) => {
      var qd = {
        where: whereCondition,
        order: 'createdTime DESC',
        limit: limits,
        skip: step,
        include: include,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationAll(data) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        limit: data?.limit,
        skip: data?.skip,
        include: data?.include,
        order: data?.order,
      };

      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${data.endPoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetMyPaginationAll(data) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        limit: data?.limit,
        skip: data?.skip,
        order: data?.order,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${data.endPoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPagination(step, limits, endPoint) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        limit: limits,
        skip: step,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  reSetPasswordRequest(email) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .post(`${environment.apiUrl}/reset-password/${email?.toLowerCase()}`, {
          email: email?.toLowerCase(),
        })
        .subscribe(
          (data: any) => {
            resolve('data');
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  itemsGetPaginationInclude(step, limits, endPoint, include) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        limit: limits,
        skip: step,
        include: include,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  addItem(data, endPoint) {
    // console.log('addItem ~ endPoint', endPoint);
    var self = this;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      }),
    };
    return new Promise((resolve, reject) => {
      // self.asyncLocalStorage
      //   .getItem('accessToken')
      //   .then(function (accessToken) {
      //     if (accessToken) {
      //       self.http.post(`${environment.apiUrl}/${endPoint}`, data).subscribe(
      //         (data) => {
      //           resolve(data); subscribeObj.unsubscribe()
      //         },
      //         (err) => {
      //
      //           reject(err); subscribeObj.unsubscribe()
      //         }
      //       );
      //     } else {
      //       localStorage.clear();
      //       self.router.navigate(['/home/login']);
      //       // self.message.error('Please login again');
      //     }
      //   });
      let subscribeObj = self.http
        .post(`${environment.apiUrl}/${endPoint}`, data, this.httpOptions) //)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  addItemFile(data, endPoint) {
    var self = this;
    return new Promise((resolve, reject) => {
      let subscribeObj = self.http
        .post(`${environment.apiUrl}/${endPoint}`, data)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  editItem(item: any, id, endPoint) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .patch(
          `${environment.apiUrl}/${endPoint}/${id}`,
          item,
          this.httpOptions
        )
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  editBookItem(item: any, id, endPoint) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .patch(
          `${environment.apiUrl}/${endPoint}/${id}`,
          item,
          this.httpOptions
        )
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getItemCount(endPoint, filter) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}/count?${filter}`,
          this.httpOptions
        )
        .subscribe(
          (data: any) => {
            // console.log('file: api.service.ts ~ line 442 ~ data', data);
            resolve(data.count);
          },
          (err) => {
            // console.log('file: api.service.ts ~ line 450 ~ err', err);
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getBookItemCount(endPoint, filter) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}/count?${filter}`,
          this.httpOptions
        )
        .subscribe(
          (data: any) => {
            // console.log('file: api.service.ts ~ line 442 ~ data', data);
            resolve(data.count);
          },
          (err) => {
            // console.log('file: api.service.ts ~ line 450 ~ err', err);
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getItemCountReport(endPoint, filter) {
    const query = `where=${JSON.stringify(filter)}`;
    // console.log('query-1: ', query);
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}/count?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data.count);
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  postItemCountReport(endPoint, templeId, stDate, endDate) {
    console.log(
      'postItemCountReport ~ endPoint, templeId, stDate, endDate',
      endPoint,
      templeId,
      stDate,
      endDate
    );
    return new Promise(async (resolve, reject) => {
      await this.http
        .post(
          `${environment.apiUrl}/${endPoint}/${templeId}/${stDate}/${endDate}`,
          ''
        )
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getMyItemCount(endPoint, filter) {
    const query = `${JSON.stringify(filter)}`;
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}/count?where=%7B%22peerTrainerId%22%3A%22${filter}%22%7D`
        )
        .subscribe(
          (data: any) => {
            resolve(data.count);
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  getItems(endPoint, filter) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${filter}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  getItemsL(endPoint) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(`https://www.examfear.com/${endPoint}`, {
          responseType: 'text',
        })
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getReport(endPoint, startDate, endDate, type) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(
          `${environment.apiUrl}/${endPoint}/${startDate}/${endDate}/${type}`
        )
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  deleteOldsServices(prodId: any) {
    // this.refreshToken();
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .delete(`${environment.apiUrl}/serviceProviders/${prodId}/subCat`)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateTags(prodId, subCatId) {
    //
    // this.refreshToken();
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .put(
          `${environment.apiUrl}/serviceProviders/${prodId}/subCat/rel/${subCatId}`,
          {}
        )
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getItemsInclude(include, endPoint, filter) {
    return new Promise(async (resolve, reject) => {
      var qd = {};
      qd = {
        include: include,
        where: filter,
      };
      const query = `filter=${JSON.stringify(qd)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`, this.httpOptions)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getItemObj(data, endpoint) {
    return new Promise(async (resolve, reject) => {
      const query = `filter=${JSON.stringify(data)}`;
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endpoint}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getSearch(endPoint, query) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  reSetPasswordAdmin(data, access) {
    return new Promise((resolve, reject) => {
      const lHttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: access,
        }),
      };

      let subscribeObj = this.http
        .post(
          `${environment.apiUrl}/reset-password?access_token=${access}`,
          data,
          lHttpOptions
        )
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  reSetPassword(data) {
    return new Promise((resolve, reject) => {
      // const lHttpOptions = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //     Authorization: access,
      //   }),
      // };

      let subscribeObj = this.http
        .put(`${environment.apiUrl}/reset-password/finish`, data)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            if (err.status == 200) {
              resolve(err);
            }
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  verifyMail(data) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .put(`${environment.apiUrl}/verify`, data)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            if (err.status == 200) {
              resolve(err);
            }
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getGeneralItems(endPoint) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  // getItemsById(id, endPoint) {
  //   return new Promise(async (resolve, reject) => {
  //     let subscribeObj = await this.http.get(`${environment.apiUrl}/${endPoint}/${id}`).subscribe(
  //       (data: any) => {
  //         resolve(data); subscribeObj.unsubscribe()
  //       },
  //       (err) => {
  //         reject(err); subscribeObj.unsubscribe()
  //       }
  //     );
  //   });
  // }
  getItemsById(id, endPoint, query) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .get(`${environment.apiUrl}/${endPoint}/${id}?${query}`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  deleteItem(id, endPoint) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .delete(`${environment.apiUrl}/${endPoint}/${id}`)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  deleteArticle(endPoint) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .delete(`${environment.apiUrl}/${endPoint}`)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  deleteItemSubCategory(id, endPoint, endPointOne) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .delete(`${environment.apiUrl}/${endPoint}/${id}/${endPointOne}`)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }
  getUserRole(): string {
    const encryptedRole = localStorage.getItem(this.USER_ROLE_KEY);

    if (encryptedRole) {
      const decryptedRole = CryptoJS.AES.decrypt(
        encryptedRole,
        this.SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);

      return decryptedRole;
    }
    return null;
  }
  getToken(): string {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return token;
    }
    return null;
  }
  //old
  loginO(loginVal) {
    let self = this;
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .post(`${environment.apiUrl}/admin/login`, loginVal)
        .subscribe(
          (data: any) => {
            console.log('file: api.service.ts ~ line 1034 ~ data_login', data);
            localStorage.setItem('accessToken', data['token']);
            localStorage.setItem('name', data['name']);
            localStorage.setItem('role', data['role']);
            localStorage.setItem('userId', data['id']);
            localStorage.setItem('status', data['status']);
            this.setUserRole(data['role']);
            // localStorage.setItem('leadId', data['user']['leadTrainerId']);
            //

            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  //end

  //new
  login(loginVal): Promise<any> {
    return this.http
      .post(`${environment.apiUrl}/admin/login`, loginVal)
      .toPromise()
      .then((data: any) => {
        localStorage.setItem('accessToken', data.token);
        localStorage.setItem('name', data.name);
        // localStorage.setItem('role', data.role);
        localStorage.setItem('userId', data.id);
        localStorage.setItem('status', data.status);
        this.setUserRole(data.role);

        return data;
      })
      .catch((error) => {
        throw error;
      });
  }

  //end
  loginTeacher(loginVal) {
    let self = this;
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .post(`${environment.apiUrl}/teachers/login`, loginVal)
        .subscribe(
          (data: any) => {
            localStorage.setItem('accessToken', data['token']);
            localStorage.setItem('name', data['userData']['name']);
            localStorage.setItem('role', data['userData']['role']);
            localStorage.setItem('userId', data['userData']['id']);
            // localStorage.setItem('leadId', data['user']['leadTrainerId']);
            //
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loginGoogle() {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .get(`${environment.apiUrl}/auth/account`)
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getSearchResults(endPoint, query) {
    return new Promise((resolve, reject) => {
      let subscribeObj = this.http
        .get(`${environment.apiUrl}/${endPoint}?${query}`)
        .subscribe(
          (data) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getRecommendQuiz(chapterId) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .post(`${environment.apiUrl}/qns-banks/random/${chapterId}`, '')
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  getRecommendVideos(sectionId, subjectId, chapterId) {
    return new Promise(async (resolve, reject) => {
      let subscribeObj = await this.http
        .post(
          `${environment.apiUrl}/videos/random/${sectionId}/${subjectId}/${chapterId}`,
          ''
        )
        .subscribe(
          (data: any) => {
            resolve(data);
            subscribeObj.unsubscribe();
          },
          (err) => {
            reject(err);
            subscribeObj.unsubscribe();
          }
        );
    });
  }

  private mailApi = 'https://mailthis.to/hanishrao321@gmail.com';
  PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' }).pipe(
      map(
        (response) => {
          if (response) {
            return response;
          } else {
            return null;
          }
        },
        (error: any) => {
          return error;
        }
      )
    );
  }

  loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute('src') != null &&
        scripts[i].getAttribute('src').includes('loader')
      ) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        'https://cdn.ckeditor.com/4.15.0/full-all/ckeditor.js',
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }
}
